import React from "react";
import { Panel, Field, Label, TextBox, CheckBox } from "lib/components";

export function AdditionalDetails(props) {
  const usePrintableVersion = props.usePrintableVersion === true;
  let pieces;
  let weight;
  let trailerType;
  let enableStatusUpdate;
  let bolNote;
  const ftlOrder = props.parentType === "ftl-order";
  const ltlOrder = props.parentType === "ltl-order";
  const assetLtlQuote = props.parentType === "asset-ltl-quote";
  if (ftlOrder) {
    pieces = <Field field="pieces" caption="Cases/Pieces" usePrintableVersion={usePrintableVersion} divider />;
    weight = <Field field="weight" caption="Weight" width={125} usePrintableVersion={usePrintableVersion} />;
    enableStatusUpdate = <CheckBox field="enable_updates" caption="Enable status update" usePrintableVersion={usePrintableVersion}/>;
    trailerType = <Field field="equipment_type_id" caption="Trailer Type" lookupModel="dispatch/web-trailer-type" width={150} usePrintableVersion={usePrintableVersion} divider />;
  }
  else if (ltlOrder || assetLtlQuote) {
    bolNote = <TextBox caption="BOL Note" multiline rows={4} field="bol_note" usePrintableVersion={usePrintableVersion} />;
  }    
  
  return (
    <Panel fillRow rowBreakDefault={false} caption="Additional Details">
      <Label caption="Additional Details" look="defaultBold4" fillRow rowBreak />
      <Field field="blnum" caption="BOL Number" usePrintableVersion={usePrintableVersion} />
      <Field field="shipper_po" width={250} caption="Shipper P.O. Number" usePrintableVersion={usePrintableVersion} required={false} />
      <Field field="shipper_si" width={250} caption="Shipper Reference Number" usePrintableVersion={usePrintableVersion} required={false} />
      <Field field="consignee_refno" caption="Consignee Reference Number" usePrintableVersion={usePrintableVersion} divider={ftlOrder} />
      {pieces}
      {weight}
      {trailerType}
      <Field field="planning_comment" caption="Internal Note" usePrintableVersion={usePrintableVersion} rowBreak />
      {bolNote}
    </Panel>
  );
}
