import { getCompanySettings, getLogger, getUserSettings } from "lib/util";

const log = getLogger("lib.pages.ContactUs");

export function ContactUs() {
	log.debug("Contact us settings user %o company %o", getUserSettings(), getCompanySettings());
  let address = getUserSettings().admin_email_address;
  if (address == null) {
    if(getCompanySettings() != null)
      address = getCompanySettings().admin_email_address;
    else
      log.error("Couldn't open email to admin because company settings weren't populated %o", getCompanySettings());
  }
  const href = "mailto:" + encodeURIComponent(address);
  window.location.href = href;
  log.debug("Set href to %s   window location: %o", href, window.location);
}
